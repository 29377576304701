@import "~antd/dist/antd.less";

#root {
  height: 100%;
}
.global_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #40a9ff;
  font-size: 24px;
  transform: translate(-50%, -50%);
}

#login .login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 40px;
  width: 400px;
}
// .formContent .ant-form-item-control {
//   float: right;
// }
// .formContent .ant-form-item-control-input {
//   width: 90%;
//   margin-left: 10%;
// }
#login .login-form-button {
  width: 100%;
}
.userinfor {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  .administer_div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .settingBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-right {
  margin: 8px;
  background-color: #dddddd;
}
.menuTable {
  padding: 10px 30px;
  border-bottom: 1px solid rgb(221, 221, 221);
  background-color: rgb(244, 244, 244);

  .menuItem {
    display: inline-block;
  }
}
// .ant-modal-body {
//   padding-bottom: 50px !important;
// }
.addMenu {
  padding: 10px 30px;
  float: right;
}
.formfooter {
  width: 100%;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;

  .ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    /* margin-bottom: 24px; */
    vertical-align: top;
  }
}
.delete_footer {
  margin-top: 10px;
}
.formContent {
  height: 85%;
  padding-bottom: 10px;
}
.formContent .ant-form-item-label label {
  min-width: 115px;
  margin-left: 40px;
}
.formContent
  .ant-form-item-row
  .ant-form-item-explain
  .ant-form-item-explain-error {
  width: 90%;
  // margin-left: 10%;
}
.dataformstext {
  margin-left: 40px;
  margin-top: 15px;
}
.buttonstyle {
  display: flex;
  .btn-1 {
    margin: 0 5px;
  }
}
.forminput {
  display: flex;
  margin: 10px 0;
  .iconinline {
    display: flex;
    margin-left: 5px;
  }
}
.forminput_btn {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  > div {
    margin-left: 5px;
  }
}
.currencyStyle {
  display: flex;
}
.labelwidth {
  min-width: 80px;
  font-size: 16px;
  font-weight: 500;
}
.formiconleft {
  margin-right: 10px;
}
.dataform_text {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.dataformicon {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.dataformshow {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .dataforminline {
    display: flex;
  }
  .dataform_map_div {
    margin-top: 5px;
  }
  .dataform_map_box {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;
    .datatextstyle {
      padding: 2px 10px;
      border: 1px solid #ccc;
      margin: 10px 5px;
      position: relative;
    }
  }
}
.datatextstyle1 {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 11px solid rgb(168, 166, 209);
  border-right: 11px solid transparent;
}
.datatextstyle2 {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 11px solid rgb(38, 122, 65);
  border-right: 11px solid transparent;
}
.datatextstyle3 {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 11px solid red;
  border-right: 11px solid transparent;
}
.seletclick_list {
  padding: 2px 0px;
  position: absolute;
  right: -6px;
  width: 64px;
  top: 6px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}
.selected_keys_style span {
  display: block;
}
.seletclick_list > div:hover {
  background-color: rgb(231, 230, 230);
  cursor: pointer;
}
.seletclick_list div {
  width: 100%;
  padding: 3px;
}
.dataformedit_div {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  .dataformedit_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .addwords_div {
    display: flex;
    flex-direction: row;
    > div:first-child {
      margin-right: 10px;
    }
  }
  .dataform_selected_div {
    position: relative;
  }
  .dataform_selected {
    border: 1px solid #ccc;
    min-height: 100px;
    width: 100%;
  }
  .dataform_selected {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px;
  }
  .dataform_button_div {
    display: flex;
    justify-content: flex-end;
  }
  .selected_keys_style {
    padding: 5px;
    border: 1px solid #ccc;
    margin: 2px 5px;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    background-color: #fff;
    color: #000;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    .ant-dropdown {
      z-index: 99 !important;
    }
  }

  .active_opacity {
    opacity: 0;
  }
}
.relate-layout {
  width: 100%;
}
.relate-box {
  width: 100%;
  border: 2px solid rgb(223, 221, 221);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
.submitbtn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@primary-color: #1DA57A;